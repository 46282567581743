export default {
    welcome: 'Welcome',
    greeting: 'Hello, {name}!',
    faq: 'FAQ',
    homeView: {
        "why boostaminophen": "Why Boostaminophen?",
        "earning income": "Make a great amount of profit and money",
        "gaming community": "Obtain the benefit of working with every popular Gaming Community",
        "boosting services": "Enjoy a variety of the best Boosting Services",
        "welcome": "Welcome to Boostaminophen",
        "welcome desc": "Boostaminophen is one of the largest Persian/Farsi Boosting Communities with more than 10 years of experience in providing boosting services for a variety of online games. Our services include all the boosting offers for most of the popular online games such as World of Warcraft, Albion Online, Diablo 4 and … We also offer a great opportunity to any gold or in-game currency suppliers/farmers to sell their products and benefit from it.",
        "invite to join": "If you are interested in joining our big-loving family, then sign up in our website now and choose your ideal role from one of the categories below to start your business with us right away.",
        "booster": "booster",
        "booster desc": "Boosters are one of the key roles in our community and their main responsibility is to fulfill any offers and services that their clients ask. Therefore, as a booster, it is necessary to have enough knowledge and experience to complete the required boost. Moreover, the faster and better you finish a boost, the more in demand you could be and more profit you can make.",
        "supplier": "Gold/Currency Farmer or Supplier",
        "supplier desc": "Another way to work with our community is selling your gold or any other in-game currency that you have. So, if you are a currency supplier or farmer and are interested in making a profit out of your hobby, then this is the best role for you.",
        "accountSeller": "Account Seller",
        "accountSeller desc": "This role is for those who have many un-used accounts and characters in different games and are deciding to sell and make a profit out of it. After signing up in our website and choosing this role, you can send your account information and some necessary screen shots of your characters to us, then our professional members will check them and contact you.",
        "faq": {
            "become booster": {
                "question": "How can I become a booster in your community?",
                "answer": "After signing up and sending us the required information regarding the game(s) you play and provide boosting services for, we will check your request and if accepted, you will get the booster role and can sign up for any suitable boost."
            },
            "rial income": {
                "question": "Will I only be paid in Rial?",
                "answer": "No. Whether you want to be paid in Rial or Dollars depends on you and is based on your request."
            },
            "exchange": {
                "question": "How will I receive my payment? ",
                "answer": "If you have chosen to be paid in dollars, then you will be required to have a digital wallet and send its address to us, we then will deliver your payment through digital currency which can be exchanged to any desired currency."
            },
            "income immediately": {
                "question": "Will I be paid instantly after I finish the boost?",
                "answer": "After finishing the boost and if it is confirmed by the client, the payment process will start and it usually takes 1 to 3 working days until you receive your payment."
            },
            "sell currency": {
                "question": "How can I sell my gold or other currencies?",
                "answer": "After joining our website and Discord, you just need to send us the amount of currency and the specified game or server so that the Gold Bustaminophen group members will guide you."
            },
            "news": {
                "question": "Where can I find additional information regarding to your boosting services?",
                "answer": "You can easily click on the following link and join our discord server where you can check all the necessary information."
            },
            "platform": {
                "question": "Do you have a platform where I can be informed about games and related news?",
                "answer": "Of course yes. You can click on the link below and follow us on Instagram in order to know about the news and any hot topics related to games and gaming industry."
            }
        }
    },
    footer: {
        "description": "Boostaminophen is one of the largest Persian/Farsi Boosting Communities with more than 10 years of experience in providing boosting services for a variety of online games."
    },
    menu: {
        home: 'Home',
        contact: 'Contact Us',
        match: 'Match'
    }
};
