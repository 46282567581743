export default {
    welcome: 'Welcome',
    greeting: 'Hello, {name}!',
    faq: 'سوالات متداول',
    homeView: {
        "why boostaminophen": "چرا بوستامینوفن؟",
        "earning income": "کسب درآمد فوق العاده و آسان هم به صورت ریالی و هم دلاری",
        "gaming community": "همکاری با تمامی کامیونیتی ها مطرح دنیا",
        "boosting services": "ارائه بهترین خدمات بوستینگ گیم های متنوع",
        "welcome": "به بوستامینوفن خوش آمدید",
        "welcome desc": "بوستامینوفن یکی از بزرگترین کامیونیتی های فارسی زبان با بیش از ده ها سال سابقه در زمینه ی بوستینگ و گیمینگ است که شامل اعضاء مجرب در زمینه ی بازی و سرگرمی می باشد. خدمات ما شامل: ارائه سرویس های بوستینگ تمامی بازی های آنلاین مانند World of Warcraft، Diablo 4، Albion Online و غیره و همچنین خرید و فروش گلد و اکانت بازی های مختلف می باشد.",
        "invite to join": "اگر شما هم می خواهید به خانواده بزرگ بوستامینوفن بپیوندید و همکاری خود را با ما آغاز کنید، همین حالا رول مناسب خود را در این قسمت انتخاب کرده و در سایت ما عضو شوید تا از خدمات ما به طور کامل بهره ببرید.",
        "booster": "بوستر",
        "booster desc": "بوسترها یکی از مهم ترین نقش ها را در کامیونیتی بوستامینوفن ایفا می کنند و وظیفه ی آن ها به عنوان کسانی که سرویس های درخواستی کلاینت ها را انجام می دهند، بسار مهم و حیاتی است. بدیهی است که هرچه میزان اطلاعات و تجربه شما به عنوان یک بوستر در بازی مورد نظر بیشتر باشد، امکان انجام بوست برای شما راحت تر و سریع تر می باشد و در نتیجه شما می توانید خدمات بیشتری ارائه داده و درآمد بیشتری داشته باشید.",
        "supplier": "فارم و فروش گلد و کارنسی",
        "supplier desc": "یکی دیگر از روش های همکاری با ما, فروش انواع گلد و کارنسی بازی های مختلف به کامیونیتی ما می باشد. شما اگر در بازی های مختلف گلد فارم می کنید و قصد کسب درآمد از طریق فروش آن را دارید، می توانید به بوستامینوفن بپیوندید و با ما همکاری کنید.",
        "accountSeller": "فروش اکانت",
        "accountSeller desc": "این قسمت مختص بازیکنانی است که در بازی های مختلف اکانت ساخته اند و تمایل به فروش و کسب درامد از طریق آن را دارند. شما با عضویت در وبسایت ما و انتخاب این رول، می توانید مشخصات و اسکرین شات از اکانت و یا کاراکتر مورد نظر خود را برای ما ارسال کنید و پس از بررسی ها ی مورد نظر، هزینه آن را به راحتی دریافت کنید.",
        "faq": {
            "become booster": {
                "question": "چطور می توانم به عنوان یک بوستر با بوستامینوفن همکاری داشته باشم؟",
                "answer": "پس از عضویت شما در وبسایت و دیسکورد ما و ارسال اطلاعات لازم، درخواست شما بررسی شده و در صورت رضایت اعضاء تیم بوستامینوفن، همکاری شما با ما آغاز می شود."
            },
            "rial income": {
                "question": "آیا درآمد من فقط ریالی است؟",
                "answer": "خیر. درآمد شما در بوستامینوفن به درخواست شما، هم ریالی و هم دلاری می تواند باشد."
            },
            "exchange": {
                "question": "چطور می توانم درآمد دلاری دریافت کنم و آن را به تومان تبدیل کنم؟",
                "answer": "شما می توانید با عضویت و ساخت یک کیف پول الکترونیک در وبسایتی مانند Wallex.ir و ارسال آدرس کیف پول خود به ما، درآمد خود را به صورت دلاری دریافت کنید و در صورت تمایل از طریق همان سایت، آن را به تومان تبدیل کنید. "
            },
            "income immediately": {
                "question": "آیا درآمد من پس از پایان بوست بلافاصله ارسال می شود؟",
                "answer": "به طور معمول تمامی پرداخت ها بین 1 تا 3 روز کاری و پس از تایید کامل شدن بوست شما، واریز می شوند."
            },
            "sell currency": {
                "question": "چطور می توانم گلد و یا سایر کارنسی های خود را بفروشم؟",
                "answer": "شما فقط کافی است پس از عضویت در وبسایت و دیسکورد ما، مقدار کارنسی و بازی و یا سرور مشخص شده را برای ما بفرستید تا اعضاء گروه گلد بوستامینوفن شما را راهنمایی کنند."
            },
            "news": {
                "question": "چگونه می توانم از اخبار و اطلاعات بیشتر در رابطه با بوست ها و بازی ها ی مختلف مطلع شوم؟",
                "answer": "برای کسب هرگونه اطلاعات بیشتر، شما می توانید در دیسکورد ما عضو شوید، همچنین برای اطلاع از اخبار روز بازی های مختلف، می توانید بوستامینوفن را در اینستاگرام دنبال کنید."
            },
            "platform": {
                "question": "چگونه می توانم وارد دیسکورد بوستامینوفن شوم؟",
                "answer": "پس از باز کردن دیسکورد، فقط کافیست بر روی لینک زیر کلیک کرده و وارد سرور ما شوید."
            }
        }
    },
    footer: {
        "description" : "بوستامینوفن یکی از بزرگترین کامیونیتی های فارسی زبان با بیش از ده ها سال سابقه در زمینه ی بوستینگ و گیمینگ است که شامل اعضاء مجرب در زمینه ی بازی و سرگرمی می باشد."
    },
    menu:{
        home: 'خانه',
        contact: 'تماس با ما',
        match: 'مسابقه'
    }
};
