import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n';
import en from './locales/en';
import fa from './locales/fa';

import "bootstrap/dist/css/bootstrap.css"
//import "bootstrap/dist/css/bootstrap.rtl.min.css"
import "@/assets/css/slick.css"
import "@/assets/css/icofont.min.css"
import "@/assets/css/animate.css"
import "@/assets/css/nice-select.css"
import "@/assets/css/magnific-popup.css"
import "@/assets/css/style.css"
import "@/assets/css/myStyle.css"
import "bootstrap"
import "jquery-nice-select"
import "jquery"
import 'swiper/swiper-bundle.css';

import $ from '@/jquery';

const i18n = createI18n({
    locale: 'fa',
    legacy: false,
    messages: {
        en,
        fa,
    }
});

const app = createApp(App);
//app.config.globalProperties.counterUp = counterUp;
app.use(store);
app.use(router);
app.use(i18n);
app.use($);
app.mount('#app')
