import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('../components/layout/default.vue'),
        meta: {
            bodyClass: ['body__bg'],
            bodyBackground: '/img/body-bg.webp'
        },
        children: [
            {
                path: 'home',
                name: 'home2',
                component: () => import('../views/HomeView2.vue')
            },
            {
                path: '',
                name: 'home',
                component: () => import('../views/HomeView.vue')
            },
        ]
    },
    {
        path: '/',
        component: () => import('../components/layout/default.vue'),
        meta: {
            bodyClass: ['body__bg'],
            bodyBackground: '/img/body-bg2.webp'
        },
        children: [
            {
                path: 'about',
                name: 'about',
                component: () => import('../views/AboutView.vue')
            },
            {
                path: 'faq',
                name: 'faq',
                component: () => import('../views/FAQ.vue')
            },
            {
                path: 'all-game',
                name: 'all-game',
                component: () => import('../views/AllGame.vue')
            },
            {
                path: 'blog-details',
                name: 'blog-details',
                component: () => import('../views/blog-details.vue')
            },
            {
                path: 'blog-details-left-sidebar',
                name: 'blog-details-left-sidebar',
                component: () => import('../views/blog-details-left-sidebar.vue')
            },
            {
                path: 'blog-details-right-sidebar',
                name: 'blog-details-right-sidebar',
                component: () => import('../views/blog-details-right-sidebar.vue')
            },
            {
                path: 'blog-grid-without-sidebar',
                name: 'blog-grid-without-sidebar',
                component: () => import('../views/blog-grid-without-sidebar.vue')
            },
            {
                path: 'players',
                name: 'players',
                component: () => import('../views/players.vue')
            },
            {
                path: 'player-details',
                name: 'player-details',
                component: () => import('../views/player-details.vue')
            },
            {
                path: 'match-details',
                name: 'match-details',
                component: () => import('../views/match-details.vue')
            },
            {
                path: 'match',
                name: 'match',
                component: () => import('../views/match.vue')
            },
            {
                path: 'game-details',
                name: 'game-details',
                component: () => import('../views/game-details.vue')
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('../views/contact.vue')
            },
            {
                path: 'blog-right-sidebar',
                name: 'blog-right-sidebar',
                component: () => import('../views/blog-right-sidebar.vue')
            },
            {
                path: 'blog-left-sidebar',
                name: 'blog-left-sidebar',
                component: () => import('../views/blog-left-sidebar.vue')
            },
            {
                path: 'blog-grid-right-sidebar',
                name: 'blog-grid-right-sidebar',
                component: () => import('../views/blog-grid-right-sidebar.vue')
            },
            {
                path: 'blog-grid-left-sidebar',
                name: 'blog-grid-left-sidebar',
                component: () => import('../views/blog-grid-left-sidebar.vue')
            },
            {
                path: 'blog-without-sidebar',
                name: 'blog-without-sidebar',
                component: () => import('../views/blog-without-sidebar.vue')
            },
            {
                path: ':pathMatch(.*)*',
                name: 'notFound',
                component: () => import('../views/404.vue')
            },
        ]
    },
    {
        path: '/slick',
        name: 'slick',
        component: () => import('../views/slick.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
