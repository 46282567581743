<template>
  <router-view/>
</template>
<script>

export default {
  name: 'HelloWorld',
}
</script>
<style lang="scss">

</style>
